function IsNewDisplay(IsNew){
  return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
}
function IsCertifiedDisplay(IsCertified){
  return (IsCertified === true ? "Certified " : "" )
}

module.exports = {
  IsNewDisplay:(IsNew) => {
    return (IsNew === true ? "New" : (IsNew === false) ? "Used" : "")
  },
  IsCertifiedDisplay:(IsCertified) => {
    return (IsCertified === true ? "Certified " : "" )
  },
  configurableCompareString: (theString, compareVehicleInfo) => {
    let finalString = theString
    finalString = finalString.replace("{{compareModel}}", compareVehicleInfo.compareModel)
    finalString = finalString.replace("{{mainModel}}", compareVehicleInfo.mainModel)
    return finalString
  },
  configurableString: (theString, VehicleInfo, MainPhotoUrl,configurablePriceInfo, msrp,extraprice1) => {
    let finalString = theString
    finalString = finalString.split("{{new}}").join( IsNewDisplay(VehicleInfo.IsNew))
    finalString = finalString.split("{{certified}}").join(IsCertifiedDisplay(VehicleInfo.IsCertified).split(' ').join(''))
    finalString = finalString.split("{{vin}}").join(  VehicleInfo.VIN)
    finalString = finalString.split("{{year}}").join( VehicleInfo.Year)
    finalString = finalString.split("{{make}}").join( VehicleInfo.Make)
    finalString = finalString.split("{{model}}").join( VehicleInfo.Model)
    finalString = finalString.split("{{trim}}").join( VehicleInfo.Trim)
    finalString = finalString.split("{{bodytype}}").join( VehicleInfo.BodyType)
    finalString = finalString.split("{{status}}").join( VehicleInfo.VehicleStatus)
    finalString = finalString.split("{{image}}").join( MainPhotoUrl)
    finalString = finalString.split("{{price}}").join( configurablePriceInfo)
    finalString = finalString.split("{{extraprice1}}").join(extraprice1)
    finalString = finalString.split("{{stock}}").join( VehicleInfo.StockNumber && VehicleInfo.StockNumber != "" ? VehicleInfo.StockNumber : "NA")
    finalString = finalString.split("{{engine}}").join( VehicleInfo.Engine && VehicleInfo.Engine != "" ? VehicleInfo.Engine  : "NA")
    finalString = finalString.split("{{transmission}}").join( VehicleInfo.Transmission && VehicleInfo.Transmission != "" ? VehicleInfo.Transmission : "NA")
    finalString = finalString.split("{{exterior}}").join( VehicleInfo.ExteriorColor && VehicleInfo.ExteriorColor != "" ? VehicleInfo.ExteriorColor : "NA")
    finalString = finalString.split("{{interior}}").join( VehicleInfo.InteriorColor && VehicleInfo.InteriorColor != "" ? VehicleInfo.InteriorColor : "NA")
    finalString = finalString.split("{{mileage}}").join( VehicleInfo.Mileage)
    finalString = finalString.split("{{msrp}}").join(msrp)
    return finalString
  },
  /*
  * remove all spaces " " and replace with dash "-"
  *
  * */
  configurableLink: (theLink, VehicleInfo) => {
    let finalLink = theLink
    finalLink = finalLink.replace("{{new}}", IsNewDisplay(VehicleInfo.IsNew))
    finalLink = finalLink.replace("{{vin}}", VehicleInfo.VIN ? VehicleInfo.VIN : "NA")
    finalLink = finalLink.replace("{{year}}", VehicleInfo.Year ? VehicleInfo.Year : 'NA"')
    finalLink = finalLink.replace("{{make}}", VehicleInfo.Make ? VehicleInfo.Make.replace(" ", "-") : "NA")
    finalLink = finalLink.replace("{{model}}", VehicleInfo.Model ? VehicleInfo.Model.replace(" ", "-") : "NA")
    finalLink = finalLink.replace("{{bodytype}}", VehicleInfo.BodyType ? VehicleInfo.BodyType.replace(" ", "-") : "NA")
    finalLink = finalLink.replace("{{status}}", VehicleInfo.VehicleStatus ? VehicleInfo.VehicleStatus.replace(" ", "-") : "NA")
    finalLink = finalLink.replace("/-/", "/")
    return finalLink
  },
  /*
  * Slug add / to the beginning of the final return
  *
  * */
  configurableSlug: (theSlug, VehicleInfo) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.replace("{{new}}", IsNewDisplay(VehicleInfo.IsNew))
    finalSlug = finalSlug.replace("{{vin}}", VehicleInfo.VIN ? VehicleInfo.VIN : "NA")
    finalSlug = finalSlug.replace("{{year}}", VehicleInfo.Year ? VehicleInfo.Year : 'NA"')
    finalSlug = finalSlug.replace("{{make}}", VehicleInfo.Make ? VehicleInfo.Make.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("{{model}}", VehicleInfo.Model ? VehicleInfo.Model.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("{{bodytype}}", VehicleInfo.BodyType ? VehicleInfo.BodyType.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("{{status}}", VehicleInfo.VehicleStatus ? VehicleInfo.VehicleStatus.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("/-/", "/")
    return "/" + (finalSlug == "" ? VehicleInfo.VIN : finalSlug)
  },
  /*
  * If you don't have the VehicleInfo data you can pass them in a indiviual items
  * */
  configurableSlugManual: (theSlug, VIN, Year, Make, Model, BodyType,IsNew) => {
    let finalSlug = theSlug
    finalSlug = finalSlug.replace("{{new}}", IsNewDisplay(IsNew))
    finalSlug = finalSlug.replace("{{vin}}", VIN ? VIN : "NA")
    finalSlug = finalSlug.replace("{{year}}", Year ? Year : "NA")
    finalSlug = finalSlug.replace("{{make}}", Make ? Make.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("{{model}}", Model ? Model.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("{{bodytype}}", BodyType ? BodyType.replace(" ", "-") : "NA")
    finalSlug = finalSlug.replace("/-/", "/")
    return "/" + (finalSlug == "" ? VIN : finalSlug)
  }
}
